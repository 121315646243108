import React, { useEffect, useState } from "react";
import { termsAndPolicies } from "data/termsAndPolicies";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import HeroHeader from "./HeroHeader";
import { Main } from "layouts";
import bgUrl from "../images/hero-header/staticBackground.svg";
import Container from "./Container";
import calendar from "../images/calendar.svg";

const TermsAndPoliciesPage = ({ pageType }) => {
  const [pageData, setPageData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    setPageData(termsAndPolicies[pageType]);
  }, [pageType]);

  if (!pageData) {
    return null;
  }

  return (
    <Box sx={{ overflowX: "hidden", minHeight: "100vh" }}>
      <Main>
        <Box sx={{ backgroundImage: `url(${bgUrl})` }}>
          <HeroHeader
            title={pageData.title}
            noBg
            description={
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"6px"} mt={"-20px"}>
                <Box component={"img"} src={calendar} loading="lazy" alt="calender logo" height={14} width={14} />
                <Typography variant="subtitleLight" color={theme.palette.text.description} component="p">
                  Updated {pageData.lastUpdated}
                </Typography>
              </Box>
            }
            Component={() => <Divider sx={{ borderColor: "#9DA8BC" }} />}
            highlightText={{
              title: {
                text: pageData.highlight,
              },
            }}
          />
          <Container color={theme.palette.text.description2}>
            <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
          </Container>
        </Box>
      </Main>
    </Box>
  );
};

export default TermsAndPoliciesPage;
