export const termsAndPolicies = {
  termsOfUse: {
    title: "Terms of use",
    highlight: "use",
    lastUpdated: "May 21,2024",
    effectiveDate: "May 21, 2024",
    content: `<h3>Effective: May 21, 2024</h3>
      Welcome to Appnigma AI! These Terms and Conditions ("Terms") govern your use of our no-code platform for generating Salesforce native code. By accessing or using our services, you agree to comply with and be bound by these Terms. Please read them carefully.

      <h3>1. Acceptance of Terms</h3>
<p>By using the Appnigma AI platform, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our services.</p>

<h3>2. Description of Services</h3>
Appnigma AI provides a no-code platform that enables users to generate Salesforce native code. Our services include:
<ul>
<li>User account creation and management</li>

<li>Subscription-based access to our platform</li>

<li>Integration and deployment of generated code to your Salesforce organization</li>
</ul>

<h3>3. User Accounts</h3>
To use our platform, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.

<h3>4. Subscription and Payment</h3>
Access to our platform requires a subscription. By subscribing, you agree to pay the subscription fees specified at the time of purchase. We use secure payment processing methods to handle your credit card information. All fees are non-refundable except as required by law.

<h3>5. User Conduct</h3>
You agree to use our platform only for lawful purposes and in accordance with these Terms. You agree not to:
<ul>
<li>Use our services in any way that violates any applicable federal, state, local, or international law or regulation.</li>

<li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of our services.</li>

<li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
</ul>

<h3>6. Intellectual Property</h3>
All content, features, and functionality on our platform, including but not limited to text, graphics, logos, and software, are the exclusive property of Appnigma AI and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, distribute, or create derivative works from any content on our platform without our prior written consent.

<h3>7. Privacy</h3>
Your use of our services is also governed by our Privacy Policy, which can be found here. By using our platform, you consent to the collection and use of your information as described in the Privacy Policy.

<h3>8. Disclaimers and Limitation of Liability</h3>
<p>Our platform is provided on an "as is" and "as available" basis. We do not warrant that our services will be uninterrupted or error-free. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including any warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

<p>In no event shall Appnigma AI, its affiliates, or their respective directors, officers, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services.</p>

<h3>9. Indemnification</h3>
You agree to indemnify, defend, and hold harmless Appnigma AI and its affiliates, and their respective directors, officers, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of our services.

<h3>10. Governing Law</h3>
These Terms and your use of our services are governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms will be brought exclusively in the federal or state courts located in California, and you hereby consent to the personal jurisdiction and venue therein.

<h3>11. Changes to Terms</h3>
We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting. Your continued use of our services following the posting of revised Terms means that you accept and agree to the changes.

<h3>12. Termination</h3>
We may terminate or suspend your account and access to our services, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use our services will immediately cease.

<h3>13. Contact Us</h3>
<p>If you have any questions or concerns about these Terms, please contact us at:</p>

<p>
  Email: <a href="mailto:hi@appnigma.ai" style="text-decoration: none !important; color: #2984FF;" aria-label="mail link">hi@appnigma.ai</a>
</p>

<p>We are committed to addressing your inquiries promptly and transparently.</p>
     `,
  },
  privacyPolicy: {
    title: "Privacy policy",
    highlight: "policy",
    lastUpdated: "May 21, 2024",
    effectiveDate: "May 21,2024",
    content: `<h3>Effective: May 21, 2024</h3>
      Appnigma AI ("we", "us", "our") operates a no-code platform for generating Salesforce native code. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information.

<h3>1. Information We Collect</h3>
We collect various types of personal data to provide and improve our services:
<ul>
<li>Personal Identification Information: This includes your name, email address, phone number, and company name. This information is necessary to create and manage your account, provide customer support, and communicate with you about our services.</li>

<li>Payment Information: We collect credit card information to process your subscription payments. We ensure that this data is handled securely and in compliance with payment processing standards.</li>
</ul>

<h3>2. How We Use Your Information</h3>
The personal data we collect is used for the following purposes:
<ul>
<li>Service Provision: We use your personal information to facilitate your use of our platform, ensuring that you can generate Salesforce native code effectively.</li>

<li>Account Management: Your personal data helps us manage your account, including authentication, account settings, and user preferences.</li>

<li>Communication: We may use your contact information to send you important updates about your account, notifications about changes to our services, and responses to your inquiries.</li>

<li>Payment Processing: Your credit card information is used to process subscription payments securely and efficiently.</li>

<li>Service Improvement: We analyze user data to understand how our services are used and to improve our platform’s functionality and user experience.</li>
</ul>
<h3>3. Data Sharing</h3>
We respect your privacy and do not share your personal data with third parties, except in the following circumstances:
<ul>
<li>Salesforce Integration: When integrating and moving your code to your Salesforce organization, your data is shared with Salesforce to facilitate this process.</li>

<li>Legal Compliance: We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
</ul>
<h3>4. Data Security</h3>
We implement robust security measures to protect your personal data from unauthorized access, use, or disclosure:
<ul>
<li>Encryption: We use encryption technologies to safeguard your data during transmission and storage.</li>

<li>Secure Servers: Our servers are secured using advanced security protocols to prevent unauthorized access.</li>

<li>Regular Security Audits: We conduct regular security audits to identify and mitigate potential vulnerabilities in our systems.</li>
</ul>
<h3>5. User Rights</h3>
You have several rights concerning your personal data, and we are committed to enabling you to exercise these rights:
<ul>
<li>Access: You can request access to the personal data we hold about you.</li>

<li>Update: If your personal information is inaccurate or incomplete, you have the right to update it.</li>

<li>Delete: You can request the deletion of your personal data, subject to certain exceptions (e.g., if we need to retain your information for legal reasons).</li>

<li>Correct: If you find any inaccuracies in your data, you have the right to correct them.</li>

<li>Opt-Out: You can opt-out of receiving promotional communications from us.</li>
</ul>

To exercise any of these rights, please contact us at hi@appnigma.ai. We will respond to your request in accordance with applicable laws.

<h3>6. Analytics</h3>
We use analytics tools to track and analyze user engagement with our platform. This information helps us understand how our services are used and identify areas for improvement. The data collected through analytics is used solely for internal purposes and is not shared with third parties.

<h3>7. Legal Compliance</h3>
We comply with applicable U.S. privacy laws and regulations, including but not limited to the California Consumer Privacy Act (CCPA). As our user base grows and if we expand our services internationally, we will take necessary steps to comply with additional privacy regulations such as the General Data Protection Regulation (GDPR).

<h3>8. Contact Us</h3>
<p>If you have any questions or concerns about your privacy, please do not hesitate to contact us:</p>

<p>
  Email: <a href="mailto:hi@appnigma.ai" style="text-decoration: none !important; color: #2984FF;" aria-label="Mail Link">hi@appnigma.ai</a>
</p>

<p>We are committed to addressing your inquiries promptly and transparently.</p>
     `,
  },
};
